import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout2 from "../components/layout2";
import Image from "../components/Image";
import getMetatags from "../util/getMetatags";
import Seo from "../components/Seo";
import { relativeToAbsulote } from "../util/common";

//import MarketplaceLogout from "../components/marketplace/logout";

function ThankyouPage({ data: { nodeThankYou }, ...props }) {
  const { relationships, calendarUrl, path } = nodeThankYou;
  const { thankyou } = relationships;
  const seo = getMetatags(
    nodeThankYou.metatag,
    nodeThankYou?.relationships?.OGImage?.localFile?.publicURL
  );

  const side = <SideThankYou thankyou={thankyou} />;
  const main = <MainThankYou thankyou={thankyou} calendarUrl={calendarUrl} />
  
  seo.meta.push({
    name: "robots",
    content: "noindex, nofollow",
  });

  useEffect(() => {
    if (path.alias === "/marketplace/thank-you") {
      if (localStorage.getItem("_FRONTEGG_ATTEMPTED_SIGNUP_") !== null) {
        const baseUrl = process.env.GATSBY_FRONTEGG_BASE_URL;
        localStorage.removeItem("_FRONTEGG_ATTEMPTED_SIGNUP_");
        window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
      }
    }
  }, [path]);

  return (
    <Layout2 side={side}>
      <Seo {...seo} />
      {main}
    </Layout2>
  );
}

const MainThankYou = ({ thankyou, calendarUrl }) => {
  return (
    <div className="lg:pt-12 lg:px-12 pt-8 px-8 pb-24 ">
      <h1 className="text-5xl py-8 leading-[3.5rem]">{thankyou.title}</h1>
      <p className="text-3xl pb-12 font-bold">{thankyou.description}</p>
      <div
        className="text-2xl pb-4"
        dangerouslySetInnerHTML={{
          __html: relativeToAbsulote(thankyou?.description_html?.processed),
        }}
      />

      <iframe
        title="Calendar"
        src={calendarUrl}
        width={"100%"}
        height={"810px"}
        className=""
      />
    </div>
  );
};

const SideThankYou = ({ thankyou }) => {
  return (
    <div className="container lg:p-20 p-6 bg-blue h-[100%]">
      <div className=" text-grey py-12 px-10 rounded-xl">
        <Image
          image={thankyou?.relationships?.image}
          alt={thankyou?.image?.alt}
        />
      </div>
    </div>
  );
};

export default ThankyouPage;

export const query = graphql`
  query thankyou($id: String!) {
    nodeThankYou(id: { eq: $id }) {
      id
      title
      calendarUrl: field_title
      path {
        alias
      }
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      relationships {
        thankyou: field_paragraph_single {
          ...ParagraphGenericBlock
        }
      }
    }
  }
`;
